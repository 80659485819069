import axios from 'axios';
import i18next from 'i18next';

function openModal() {
  document.getElementById('modalBubbles').style.display = 'block';
}

function closeModal() {
  document.getElementById('modalBubbles').style.display = 'none';
}

function showDataInModal(data) {
  const modalContent = document.querySelector('.modal_сontent');

  const html = `
    <p class='labelModal' style='color: ${data.fill};'>${data.label}</p>
    <p> ${i18next.t('riskText', {
      number: data.number,
      pointsText: data.pointsText,
    })}</p>
    <p>${i18next.t('riskPercentage', { percentage: data.percentage })}</p>
`;

  modalContent.innerHTML = html;
}
const getCorrectForm = number => {
  if (number === 1) {
    return 'one';
  }
  if (number >= 2 && number <= 4) {
    return 'few';
  }
  return 'many';
};

const calculateSize = percent => {
  switch (true) {
    case percent >= 75:
      return 10;
    case percent >= 50:
      return 9;
    case percent >= 30:
      return 8;
    case percent >= 20:
      return 7;
    case percent >= 15:
      return 6;
    case percent >= 10:
      return 5;
    case percent >= 5:
      return 4;
    case percent >= 1:
      return 3;
    case percent >= 0.5:
      return 2;
    case percent >= 0.01:
      return 0;
    default:
      return 0;
  }
};
const calculateRisk = percent => {
  switch (true) {
    case percent >= 75:
      return 0;
    case percent >= 50:
      return 1;
    case percent >= 30:
      return 2;
    case percent >= 20:
      return 3;
    case percent >= 15:
      return 4;
    case percent >= 10:
      return 5;
    case percent >= 5:
      return 6;
    case percent >= 1:
      return 7;
    case percent >= 0.5:
      return 8;
    case percent >= 0.01:
      return 9;
    default:
      return 9;
  }
};
const calculateXLabels = innerWidth => {
  switch (true) {
    case innerWidth <= 950:
      return -80;

    default:
      return -90;
  }
};
const calculateWidth = innerWidth => {
  switch (true) {
    case innerWidth > 500:
      return 1000;
    case innerWidth <= 500:
      return window.innerWidth * 1.5;
    default:
      return 1000;
  }
};

function customSort(arr) {
  const filteredArr = arr.filter(item => item.token_name !== 'PAXG');

  const sortedArr = [...filteredArr];
  sortedArr.sort((a, b) => b.percentage - a.percentage);

  const shiftArr = sortedArr.slice(1);
  const maxElement = sortedArr[0];
  const smallerValues = shiftArr
    .slice(shiftArr.length / 2)
    .sort((a, b) => a.percentage - b.percentage);
  const largerValues = shiftArr
    .slice(0, shiftArr.length / 2)
    .sort((a, b) => a.percentage - b.percentage);
  const result = [];

  if (smallerValues.length > 0) {
    result.push(maxElement);
  }

  for (
    let i = 0;
    i < Math.max(smallerValues.length, largerValues.length);
    i++
  ) {
    if (smallerValues[i]) {
      result.push(smallerValues[i]);
    }
    if (largerValues[i]) {
      result.push(largerValues[i]);
    }
  }

  arr.forEach(item => {
    if (!result.includes(item) && item.token_name !== 'PAXG') {
      result.push(item);
    }
  });

  return result;
}

const graficBubbles = async () => {
  const API_Capitalisation = `${process.env.WALLET_URL}v4/capital-manager/balances`;

  const colors = {
    0: '#DC6438',
    1: '#DE8635',
    2: '#DE8635',
    3: '#BEB949',
    4: '#98C160',
    5: '#9CC05D',
    6: '#9CC05D',
    7: '#7EC66F',
    8: '#61CC80',
    9: '#42D393',
    10: '#42D393',
  };

  try {
    const responseCapitalisation = await axios.get(API_Capitalisation);
    const dataCapitalisation = responseCapitalisation.data.balances;
    const data = [
      {
        token_name: 'USDC',
        percentage: '40.68',
      },
      {
        token_name: 'FRAX',
        percentage: '13.59',
      },
      {
        token_name: 'TUSD',
        percentage: '6.8',
      },
      {
        token_name: 'DAI',
        percentage: '6.79',
      },
      {
        token_name: 'USDD',
        percentage: '0.11',
      },
      {
        token_name: 'USDT',
        percentage: '1.91',
      },
    ];
    const processedData = customSort(dataCapitalisation).map((item, index) => {
      const { token_name, percentage, image } = item;
      const color = colors[calculateSize(percentage)];
  
      return {
        size: calculateSize(percentage) * 8,
        risk: calculateRisk(percentage),
        percentage: percentage,
        fill: color,
        label: token_name,
        image,
      };
    });
    const width = calculateWidth(window.innerWidth);

    const height = 430;
    const margin = { top: 20, right: 0, bottom: 90, left: 50 };
    const innerWidth = width;
    const innerHeight = height - margin.top - margin.bottom;

    const svg = d3
      .select('#grafic_bubbles_wrapper')
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);
    const xScale = d3
      .scaleBand()
      .domain(processedData.map(d => d.label))
      .range([0, innerWidth])
      .padding(1);

    const yScale = d3.scaleLinear().domain([0, 10]).range([innerHeight, 0]);

    const xAxis = d3.axisBottom(xScale).tickSizeOuter(0);

    svg
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0, ${innerHeight})`)
      .call(xAxis)
      .selectAll('text')
      .attr('text-anchor', 'start')
      .attr('transform', 'rotate(0)')
      .attr('class', 'text-label')
      .attr('x', calculateXLabels(window.innerWidth))
      .attr('y', 10);
    svg
      .select('.x-axis path')
      .style('stroke', 'rgb(28, 114, 147,0.15)')
      .style('stroke-width', '3');

    const yAxis = d3.axisLeft(yScale).tickSizeInner(-width).tickSizeOuter(0);

    const yAxisGroup = svg.append('g').attr('class', 'y-axis').call(yAxis);
    yAxisGroup.selectAll('line').style('stroke', 'rgb(28, 114, 147,0.15)');
    yAxisGroup.selectAll('text').attr('x', -20);
    const defs = svg.append('defs');

    const gradient = defs
      .append('linearGradient')
      .attr('id', 'gradient')
      .attr('gradientUnits', 'userSpaceOnUse')
      .attr('x1', 0)
      .attr('y1', yScale(0))
      .attr('x2', 0)
      .attr('y2', yScale(10));

    gradient.append('stop').attr('offset', '0%').attr('stop-color', '#41d495');
    gradient.append('stop').attr('offset', '50%').attr('stop-color', '#e1b133');
    gradient
      .append('stop')
      .attr('offset', '100%')
      .attr('stop-color', '#d93c3b');

    svg
      .select('.y-axis path')
      .style('stroke', 'url(#gradient)')
      .style('stroke-width', '5');
    function calculateHorizontalPosition(index) {
      if (window.innerWidth < 400) {
        return index * 80 + 30;
      } else if (window.innerWidth < 500) {
        return index * 100 + 40;
      } else {
        return index * 139 + 85;
      }
    }

    const bubbles = svg
      .selectAll('.bubble')
      .data(processedData)
      .enter()
      .append('g')
      .attr('class', 'bubble')
      .attr(
        'transform',
        (d, index) =>
          `translate(${calculateHorizontalPosition(index)},${innerHeight})`
      );

    // Функция анимации
    function animateBubbles() {
      bubbles
        .transition()
        .duration(2000)
        .attr(
          'transform',
          (d, index) =>
            `translate(${calculateHorizontalPosition(index)},${
              innerHeight - 32 * d.risk
            })`
        );
    }

    // Создаём observer для отслеживания видимости графика
    let observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          // Проверяем, входит ли элемент в область видимости
          if (entry.isIntersecting) {
            // Запускаем анимацию
            animateBubbles();
            // Отключаем observer после анимации
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    ); // threshold - это часть элемента, которая должна быть видима для срабатывания

    // Находим элемент графика и применяем к нему observer
    const graficBubblesElement = document.getElementById(
      'grafic_bubbles_wrapper'
    );
    observer.observe(graficBubblesElement);

    bubbles
      .append('image')
      .attr('xlink:href', d => d.image)
      .attr('width', d => 22)
      .attr('height', d => 22)
      .attr('x', d => -10)
      .attr('y', d => -25);
    bubbles
      .append('circle')
      .attr('r', d => (d.size > 20 ? d.size : 30))
      .style('fill', (d, i) => `url(#outer-gradient-${i})`)
      .attr('stroke', d => d.fill)
      .attr('stroke-width', 1)
      .style('cursor', 'pointer')
      .on('click', function (event, d) {
        const data = {
          label: d.label,
          number: d.risk,
          pointsText: i18next.t(`points.${getCorrectForm(d.risk)}`),
          percentage: d.percentage,
          fill: d.fill,
        };
        showDataInModal(data);
        openModal();
      })
      .on('mouseover', function (event, d) {
        const index = processedData.indexOf(d);
        d3.select(`#outer-gradient-${index}`)
          .transition()
          .duration(200)
          .attr('r', '40%');
      })
      .on('mouseout', function (event, d) {
        const index = processedData.indexOf(d);
        d3.select(`#outer-gradient-${index}`)
          .transition()
          .duration(200)
          .attr('r', '50%');
      });
    const outerGradient = bubbles
      .append('defs')
      .append('radialGradient')
      .attr('id', (d, i) => `outer-gradient-${i}`)
      .attr('cx', '50%')
      .attr('cy', '50%')
      .attr('r', '50%');

    outerGradient
      .append('stop')
      .attr('offset', '70%')
      .style('stop-color', d => d.fill)
      .style('stop-opacity', '0');

    outerGradient
      .append('stop')
      .attr('offset', '100%')
      .style('stop-color', d => d.fill)
      .style('stop-opacity', '1');

    const innerGradient = bubbles
      .append('defs')
      .append('radialGradient')
      .attr('id', (d, i) => `inner-gradient-${i}`)
      .attr('cx', '50%')
      .attr('cy', '50%')
      .style('cursor', 'pointer')
      .attr('r', '50%')
      .on('click', function (event, d) {
        const data = {
          label: d.label,
          number: d.risk,
          pointsText: i18next.t(`points.${getCorrectForm(d.risk)}`),
          percentage: d.percentage,
          fill: d.fill,
        };
        showDataInModal(data);
        openModal();
      })
      .on('mouseover', function (event, d) {
        const index = processedData.indexOf(d);
        d3.select(`#outer-gradient-${index}`)
          .transition()
          .duration(200)
          .attr('r', '40%');
      })
      .on('mouseout', function (event, d) {
        const index = processedData.indexOf(d);
        d3.select(`#outer-gradient-${index}`)
          .transition()
          .duration(200)
          .attr('r', '50%');
      });

    innerGradient
      .append('stop')
      .attr('offset', '0%')
      .style('stop-color', 'transparent');

    innerGradient
      .append('stop')
      .attr('offset', '100%')
      .style('stop-color', 'transparent');

    bubbles
      .append('text')
      .text(d => d.label)
      .attr('text-anchor', 'middle')
      .attr('dy', '0.85em')
      .style('cursor', 'pointer')
      .attr('class', 'text-label')
      .on('click', function (event, d) {
        const data = {
          label: d.label,
          number: d.risk,
          pointsText: i18next.t(`points.${getCorrectForm(d.risk)}`),
          percentage: d.percentage,
          fill: d.fill,
        };
        showDataInModal(data);
        openModal();
      })
      .on('mouseover', function (event, d) {
        const index = processedData.indexOf(d);
        d3.select(`#outer-gradient-${index}`)
          .transition()
          .duration(200)
          .attr('r', '40%');
      })
      .on('mouseout', function (event, d) {
        const index = processedData.indexOf(d);
        d3.select(`#outer-gradient-${index}`)
          .transition()
          .duration(200)
          .attr('r', '50%');
      });

    const simulation = d3.forceSimulation(processedData).force('collision');

    simulation.nodes(processedData).on('tick', () => {
      bubbles.attr('transform', d => `translate(${d.x},${d.y})`);
    });
  } catch (error) {
    console.log('Error fetching wallets count:', error);
  }
  const colorsY = {
    0: '#3ED497',
    1: '#5DCE84',
    2: '#7BC670',
    3: '#99C15F',
    4: '#B8BA4C',
    5: '#D5B33A',
    6: '#DFA133',
    7: '#DE8935',
    8: '#DD7038',
    9: '#DB5739',
    10: '#D93C3B',
  };
  let textElements = document.querySelectorAll('.y-axis text');

  textElements.forEach((element, index) => {
    element.style.fill = colorsY[index];
  });

  document.getElementById('closeModal').addEventListener('click', function () {
    closeModal();
  });

  document.querySelector('.modal').addEventListener('click', function (event) {
    if (event.target === event.currentTarget) {
      closeModal();
    }
  });
  document
    .querySelector('.modal_сontent')
    .addEventListener('click', function (event) {
      closeModal();
    });
  let isScrolling = false;

  window.addEventListener('scroll', function () {
    if (!isScrolling) {
      window.requestAnimationFrame(function () {
        closeModal();
        isScrolling = false;
      });
      isScrolling = true;
    }
  });
};

document.addEventListener('DOMContentLoaded', () => {
  graficBubbles();
});
